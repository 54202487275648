// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Athlete, Event, Result, AthleteEvent } = initSchema(schema);

export {
  Athlete,
  Event,
  Result,
  AthleteEvent
};