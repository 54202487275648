import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useLocation, Link } from "react-router-dom";

import { Input } from "rsuite";
import styles from "./_header.module.scss";
import "rsuite/dist/rsuite.min.css";
import './override.scss';

// import "rsuite/dist/rsuite.min.css";

const Header = () => {
  const location = useLocation();
  console.log("lo", location);

  const tabs = [
    {
      title: "News",
      route: "news",
      pathname: "/news",
    },
    {
      title: "Races",
      route: "races",
      pathname: "/races",
    },
    {
      title: "Rankings",
      route: "rankings",
      pathname: "/rankings",
    },
    {
      title: "Athletes",
      route: "athletes",
      pathname: "/athletes",
    },
  ];

  return (
    <header className={styles.header}>
      <div>Tri Rank</div>
      <div className={styles.nav_links}>
        {tabs.map((tab) => (
          <Link to={tab.route} key={tab.route} className={location.pathname === tab.pathname ? styles.active : null}>
            {tab.title}
          </Link>
        ))}
      </div>
      <div className={styles.header_search_wrapper}>
        <Input type="text" size="sm" placeholder="Search athletes, races, etc" className={styles.custom_input} />
      </div>
      <div className={styles.icon_group}>
        <FontAwesomeIcon icon={faUser} />
        {/* <FontAwesomeIcon icon={faBars} /> */}
      </div>
    </header>
  );
};

export default Header;
