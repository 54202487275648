import styles from "./_raceItem.module.scss";

const RaceItem = (props) => {
  const { data } = props;

  return (
    <div className={styles.race_tile}>
      <div>
        {data.name} - {data.location}
      </div>
      <div>{data.date}</div>
      <div>{data.org}</div>
      <div>{data.country}</div>
    </div>
  );
};

export default RaceItem;
