import { Outlet } from "react-router-dom";
import Header from "./components/frame/Header/Header";
import styles from "./App.module.scss";
import 'rsuite/styles/index.less';

const App = () => {
  return (
    <div className={styles.app_container}>
      <Header />
      <Outlet />
    </div>
  );
};

export default App;
