import { Grid, Row, Col, Button } from "rsuite";
import RaceItem from "../../components/RaceItem/RaceItem";

import { createData, queryData } from "./raceDataModel";

import styles from "./_races.module.scss";

import "rsuite/dist/rsuite.min.css";

// Temp data
import mockData from "../../data/mock-races.json";
import { useEffect, useState } from "react";

const Races = () => {
  const [ath, setAth] = useState([]);

  useEffect(() => {
    queryData().then((data) => setAth(data));
  }, []);

  console.log("ath", ath);

  return (
    <Grid fluid style={{ width: "100%" }}>
      <Row gutter={16} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col sm={4}>
          <div className={styles.tile} style={{ width: "100%" }}>
            Filters
          </div>
          {/* <Button onClick={createData}>Add Data</Button> */}
        </Col>
        <Col sm={16}>
          <Row gutter={16} style={{ marginBottom: "1rem" }}>
            <Col sm={24}>Tab Tab Tab Search</Col>
          </Row>
          {ath.map((mockRace, index) => (
            <Row gutter={16} style={{ marginTop: "1rem", marginBottom: "1rem" }} key={index}>
              <Col sm={24}>
                <RaceItem data={mockRace} />
              </Col>
            </Row>
          ))}
        </Col>
        <Col sm={4}>
          <div className={styles.tile} style={{ width: "100%" }}>
            ads
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default Races;
