import { Grid, Row, Col } from "rsuite";

import styles from "./_news.module.scss";

import "rsuite/dist/rsuite.min.css";

const News = () => {
  return (
    <Grid fluid>
      <Row gutter={16} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col sm={24}>
          <div className={styles.tile} style={{ width: "100%" }}>
            Countdown to IRONMAN Kona World Championships
          </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col sm={24}>
          <div className={styles.tile} style={{ width: "100%", padding: "2rem" }}>
            <h1>Welcome to Tri Rank</h1>
            <p>Hang tight for more comming very soon. Check back tomorrow for even more changes</p>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};

export default News;
