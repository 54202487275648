import { DataStore } from "@aws-amplify/datastore";
import { Athlete, Result, Event } from "../../models";

export const createData = async () => {
  // await DataStore.save(
  //   new Athlete({
  //     name: "Domenick DiBiase",
  //     ageGroup: "25-29",
  //     Events: [],
  //     Results: [],
  //   })
  // );
  // await DataStore.save(
  //   new Result({
  //     place: "Lorem ipsum dolor sit amet",
  //     overallTime: "Lorem ipsum dolor sit amet",
  //     eventID: "a3f4095e-39de-43d2-baf4-f8c16f0f6f4d",
  //     athleteID: "a3f4095e-39de-43d2-baf4-f8c16f0f6f4d",
  //   })
  // );

  await DataStore.save(
    new Event({
      name: "2022 IRONMAN 70.3 Augusta",
      location: "Augusta, GA",
      country: "US",
      date: "Aug 25, 2022",
      year: "2022",
      organization: "IRONMAN",
      participants: "1986",
      distance: "IRONMAN 70.3",
      Athletes: [],
      Results: [],
    })
  );
};

export const queryData = async () => {
  // const athleteModels = await DataStore.query(Athlete);
  // console.log(athleteModels);
  // return athleteModels;

  // const resultModels = await DataStore.query(Result);
  // console.log(resultModels);
  // return resultModels;

  const eventModels = await DataStore.query(Event);
  console.log(eventModels);
  return eventModels;
};

export const updateData = async () => {
  const CURRENT_ITEM = {};
  /* Models in DataStore are immutable. To update a record you must use the copyOf function
 to apply updates to the item’s fields rather than mutating the instance directly */
  await DataStore.save(
    Athlete.copyOf(CURRENT_ITEM, (item) => {
      // Update the values on {item} variable to update DataStore entry
    })
  );

  /* Models in DataStore are immutable. To update a record you must use the copyOf function
 to apply updates to the item’s fields rather than mutating the instance directly */
  await DataStore.save(
    Result.copyOf(CURRENT_ITEM, (item) => {
      // Update the values on {item} variable to update DataStore entry
    })
  );

  /* Models in DataStore are immutable. To update a record you must use the copyOf function
 to apply updates to the item’s fields rather than mutating the instance directly */
  await DataStore.save(
    Event.copyOf(CURRENT_ITEM, (item) => {
      // Update the values on {item} variable to update DataStore entry
    })
  );
};

export const deleteModels = async () => {
  const athleteModelToDelete = await DataStore.query(Athlete, 123456789);
  DataStore.delete(athleteModelToDelete);

  const resultModelToDelete = await DataStore.query(Result, 123456789);
  DataStore.delete(resultModelToDelete);

  const eventModelToDelete = await DataStore.query(Event, 123456789);
  DataStore.delete(eventModelToDelete);
};
